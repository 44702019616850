import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './video-list.component.scss'
import VideoListItemComponent from './video-list-item.component'

class VideoListComponent extends Component {
  render() {
    const videoItems = this.props.videos.map((video) => this.videoItem(video))
    return (
      <div className="video-list-wrapper">
        <div className={this.listHeaderClass()}>{this.props.listHeader}</div>
        <div className="videos">
          {videoItems}
        </div>
      </div>
    )
  }

  videoItem(video) {
    return (
      <div key={video.videoId} onClick={() => this.selectVideo(video)} className="video-item">
        <div className="check-back-vid-container">
          <VideoListItemComponent video={video} patientPerspectives={this.props.patientPerspectives} />
        </div>
      </div>
    )
  }

  selectVideo(selectedVideo) {
    this.props.selectVideo(selectedVideo)
  }

  listHeaderClass() {
    return this.props.listHeader ? 'list-header' : 'list-header-gone'
  }
}

const { string, arrayOf, object, func, bool } = PropTypes

VideoListComponent.propTypes = {
  listHeader: string,
  videos: arrayOf(object).isRequired,
  selectVideo: func.isRequired,
  patientPerspectives: bool,
}

export default VideoListComponent
