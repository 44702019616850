import React, { Component } from 'react'
import PropTypes from 'prop-types'
import playButton from '../../assets/images/icons/pediatric/video-play-button-gray.svg'

import './video-list-item.component.scss'

class VideoListItemComponent extends Component {
  render() {
    return (
      <div className={this.displayItem()}>
        <div className={this.selectedPatientPerspective()}>
          <div className={this.isSelectedClass()}>
            <img src={this.props.video.src} alt={this.props.video.alt} />
            <div className="now-playing-overlay" />
            <div className="now-playing-text">Now Playing</div>
          </div>
          <div className="content">
            <div className="title-play-wrapper">
              <img src={playButton} className={this.playButton()} />
              <div className="title">{this.props.video.title}</div>
            </div>
            <div className="sub-title">{this.props.video.subTitle}</div>
          </div>
        </div>
      </div>
    )
  }

  selectedPatientPerspective() {
    if (this.props.patientPerspectives && this.props.video.selected) {
      return 'caregiver-selected-wrapper'
    } else {
      return 'non-pediatric'
    }
  }

  isSelectedClass() {
    return this.props.video.selected ? 'video-wrapper-selected' : 'video-wrapper'
  }

  playButton() {
    return this.props.video.playButton ? 'play-button' : 'play-button-gone'
  }

  displayItem() {
    return this.props.video.display ? 'video-list-item-wrapper' : 'hide-list-item'
  }
}

const { object, bool } = PropTypes

VideoListItemComponent.propTypes = {
  video: object.isRequired,
  patientPerspectives: bool
}

export default VideoListItemComponent
