import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './video-library.component.scss'
import VideoListComponent from './video-list.component'
import VideoPlayerDetailComponent from './video-player-detail.component'

class VideoLibraryComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videos: props.videos,
      selectedVideo: props.selectedVideo,
      videoClass: props.videoClass,
      videoTitleColon: props.videoTitleColon,
      autoPlayVideos: props.autoPlayVideos,
      trackPercentComplete: props.trackPercentComplete
    }
    this.selectVideo = this.selectVideo.bind(this)
    this.selectVideo(this.state.selectedVideo)
  }

  render() {
    return (
      <div className="video-library-wrapper">
        {this.props.title}
        <p className="subtitle">{this.props.subtitle}</p>
        <div className="video-library-content">
          <VideoPlayerDetailComponent
            video={this.state.selectedVideo}
            videoClass={this.state.videoClass}
            videoTitleColon={this.state.videoTitleColon}
            videos={this.state.videos}
            autoPlayVideos={this.state.autoPlayVideos}
            nextVideoSelected={this.selectVideo}
            trackPercentComplete={this.state.trackPercentComplete}
            location={this.props.location}
          />

          <VideoListComponent
            videos={this.state.videos}
            selectVideo={(video) => this.selectVideo(video)}
            listHeader={this.props.listHeader}
            patientPerspectives={this.props.patientPerspectives}
          />
        </div>
      </div>
    )
  }

  selectVideo(selectedVideo) {
    const videosUpdated = this.state.videos.map((video) => {
      video.selected = video.videoId === selectedVideo.videoId
      return video
    })
    this.setState({ videos: videosUpdated, selectedVideo: selectedVideo })
    if (this.props.scrollToTop) {
      this.props.scrollToTop()
    }
  }
}

const { string, arrayOf, object, func, bool } = PropTypes

VideoLibraryComponent.propTypes = {
  listHeader: string,
  title: object.isRequired,
  subtitle: string.isRequired,
  videos: arrayOf(object).isRequired,
  selectedVideo: object.isRequired,
  videoClass: object,
  videoTitleColon: object,
  scrollToTop: func,
  playButton: object,
  autoPlayVideos: bool,
  patientPerspectives: bool,
  trackPercentComplete: bool,
}

export default VideoLibraryComponent
